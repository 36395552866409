exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artworks-js": () => import("./../../../src/pages/artworks.js" /* webpackChunkName: "component---src-pages-artworks-js" */),
  "component---src-pages-codearts-js": () => import("./../../../src/pages/codearts.js" /* webpackChunkName: "component---src-pages-codearts-js" */),
  "component---src-pages-collaborations-js": () => import("./../../../src/pages/collaborations.js" /* webpackChunkName: "component---src-pages-collaborations-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-reflections-js": () => import("./../../../src/pages/reflections.js" /* webpackChunkName: "component---src-pages-reflections-js" */),
  "component---src-pages-sketch-js": () => import("./../../../src/pages/sketch.js" /* webpackChunkName: "component---src-pages-sketch-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-project-post-js": () => import("./../../../src/templates/project-post.js" /* webpackChunkName: "component---src-templates-project-post-js" */)
}

